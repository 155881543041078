import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { FcExpand } from 'react-icons/fc';
import { navigate, useLocation } from "@reach/router";
import clsx from 'clsx';
import GlobalLayout from "../layouts/globalLayout";
import SectionHeader from "../components/SectionHeader/sectionHeader";
import styles from './services.module.css';
import PictureCard from "../components/PictureCard/pictureCard";
import { Grid } from "@material-ui/core";

const imagesQuery = graphql`
  query {
    microneedlingPic: file(
      relativePath: { eq: "services/skinpen.jpg" }
    ) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    botoxPic: file(
        relativePath: { eq: "services/botoxService.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    diamondGlowPic: file(
        relativePath: { eq: "services/diamondGlowService.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    fillerPic: file(
        relativePath: { eq: "services/fillerService.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    dermaplaningPic: file(
        relativePath: { eq: "services/AA_SSA.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    prfmPic: file(
        relativePath: { eq: "services/Mature_pic_SSA.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    facialsPic: file(
        relativePath: { eq: "services/spa2_ssa.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    chemicalPeelsPic: file(
        relativePath: { eq: "services/spa_ssa.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    kybellaPic: file(
        relativePath: { eq: "services/male_kybella.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    laserPic: file(
        relativePath: { eq: "services/laserService.jpeg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterCollagen90: file(
        relativePath: { eq: "services/beforeafter_collagen90.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterAlgomask: file(
        relativePath: { eq: "services/beforeafter_algomask.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterHydrolifting: file(
        relativePath: { eq: "services/beforeafter_hydrolifting.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterOxygenating: file(
        relativePath: { eq: "services/beforeafter_oxygenating.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterSeacspa: file(
        relativePath: { eq: "services/beforeafter_seacspa.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterDermrenewalpeel: file(
        relativePath: { eq: "services/beforeafter_dermrenewalpeel.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterLaserClearLiftPlus: file(
        relativePath: { eq: "services/beforeafter_laser_clearliftplus.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterLaserDye: file(
        relativePath: { eq: "services/beforeafter_laser_dyevl.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterLaserSkinResurfacing: file(
        relativePath: { eq: "services/beforeafter_laser_skinresurfacing.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterLaserHairRemoval: file(
        relativePath: { eq: "services/beforeafter_laser_hairremoval.jpg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    diamondGlowDiagram: file(
        relativePath: { eq: "services/DiamondGlowDiagram.png" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterSkinPen1: file(
        relativePath: { eq: "services/beforeafter_skinpen1.jpeg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterSkinPen2: file(
        relativePath: { eq: "services/beforeafter_skinpen2.jpeg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterSkinPen3: file(
        relativePath: { eq: "services/beforeafter_skinpen3.jpeg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterSkinPen4: file(
        relativePath: { eq: "services/beforeafter_skinpen4.jpeg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    beforeAfterSkinPen5: file(
        relativePath: { eq: "services/beforeafter_skinpen5.jpeg" }
    ) {
        childImageSharp {
            fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
            }
        }
    },
}`;

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
// order is important
const TAB_IDS = ['botox', 'fillers', 'kybella', 'microneedling', 'prfm', 'laser', 'dermaplaning', 'chemical_peels', 'diamond_glow', 'facials'];

export default function Services() {
    const images = useStaticQuery(imagesQuery);
    const [activeServices, setActiveServices] = useState([]);
    const topmostIndex = useTopMostDiv();
    const url = useLocation();
    
    const handleChange = (event, newValue) => {
        navigate(`/services#${TAB_IDS[newValue]}`);
        setActiveServices(prevActive => {
            prevActive.push(TAB_IDS[newValue]);

            return prevActive;
        });
    };

    useEffect(() => {
        if (url) {
            const idInUrl = TAB_IDS.find(id => url.hash.indexOf(id) !== -1);
            if (idInUrl) {
                setActiveServices(prevActive => {
                    prevActive.push(idInUrl);
        
                    return prevActive.slice();
                });
            }
        }
    }, [url]);

    return (
        <GlobalLayout>
            <Grid container>
                <Hidden smDown>
                    <Grid item xs={3} className={styles.tabsContainer}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={topmostIndex}
                            onChange={handleChange}
                            aria-label="Services Menu"
                            className={styles.tabs}
                            TabIndicatorProps={{
                                className: styles.tabIndicator
                            }}
                        >
                        <Tab label="Botox" {...a11yProps(0)} className={styles.tab} />
                        <Tab label="Fillers" {...a11yProps(1)} className={styles.tab} />
                        <Tab label="Kybella" {...a11yProps(2)} className={styles.tab} />
                        <Tab label="Microneedling" {...a11yProps(3)} className={styles.tab} />
                        <Tab label="PRFM" {...a11yProps(4)} className={styles.tab} />
                        <Tab label="Laser" {...a11yProps(5)} className={styles.tab} />
                        <Tab label="Dermaplaning" {...a11yProps(6)} className={styles.tab} />
                        <Tab label="Chemical Peels" {...a11yProps(7)} className={styles.tab} />
                        <Tab label="Diamond Glow" {...a11yProps(8)} className={styles.tab} />
                        <Tab label="Facials" {...a11yProps(9)} className={styles.tab} />
                    </Tabs>
                </Grid>                 
            </Hidden>
            <Grid item xs={12}>
                    <ServiceWrapper 
                        fluid={images.botoxPic.childImageSharp.fluid}
                        cardText=""
                        id="botox"
                        title="Botox®"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                        descriptions={[
                            'Botox® (onabotulinumtoxinA) targets one of the underlying causes of wrinkles, which is repeated and dynamic movement of the facial muscles. Botox® works by temporarily reducing this muscle activity, therefore smoothing out wrinkles. With adequate dosing, Botox® usually lasts around 3-4 months. At your consultation, we will customize a plan to see how many units are appropriate for you. Our goal is to give you a natural, not “frozen”, look.',
                            'We also offer other neuromodulator options including Dysport® to help decrease fine lines. Call us for a consultation today.',
                        ]}
                    />
                    <ServiceWrapper 
                        fluid={images.fillerPic.childImageSharp.fluid}
                        cardText=""
                        id="fillers"
                        title="Fillers"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                        descriptions={[
                            'As we age we develop wrinkles from loss of skin laxity, bone resorption and some loss of our facial fat pads. All these factors can lead to a loss of facial volume and structure. Hyaluronic fillers can create a natural, rested look by adding volume to the face while helping to smooth out wrinkles. These fillers can help treat volume loss of the lips, midface, nasolabial folds, and marionette lines. It can also treat wrinkles around the mouth and add structure to areas such as the chin. Hyaluronic acid is naturally produced by our skin and body. When Hyaluronic acid is injected into the skin it binds with water to expand, therefore “plumping” the skin. We offer many fillers such as: Juvederm® and Restylane®. Filler selection depends on multiple factors. We will ensure the right filler is chosen for your unique needs during a consultation.',
                        ]}
                    />
                    <ServiceWrapper 
                        fluid={images.kybellaPic.childImageSharp.fluid}
                        cardText=""
                        id="kybella"
                        title="Kybella®"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                        descriptions={[
                            'Kybella® (deoxycholic acid) is an FDA approved injectable treatment for destroying fat cells of the submental region, also known as the “double chin.” When injected into this area, Kybella® will destroy the fat cells resulting in a reduction of fullness under the chin. On average, three Kybella® treatments given at least one month apart are required to achieve desired aesthetic results. Call for a consultation to see if you are a candidate for Kybella®.',
                        ]}
                    />
                    <ServiceWrapper 
                        fluid={images.microneedlingPic.childImageSharp.fluid}
                        id="microneedling"
                        title="Microneedling"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                    >
                        <div className={clsx(styles.description, styles.marginTop)}>
                            <div>
                                <b>SkinPen®</b> is the first FDA-cleared, class 2 microneedling device which utilizes an advanced sterile needle cartridge with 12 pinpoint needles to penetrate vertically into the skin. The stamping action of the SkinPen's needles create invisible micro-injuries to the skin while stimulating the skin's natural ability to heal itself and increase the production of new collagen. In doing so, these micro-injuries encourage and harness the body's innate ability to repair the skin by remodeling collagen, leaving the skin rejuvenated and refreshed. SkinPen® is designed to improve the appearance of fine lines, wrinkles and acne scars.
                            </div>
                            <div className={styles.skinPenPics}>
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px"
                                    }}
                                    fluid={images.beforeAfterSkinPen1.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px",
                                        marginTop: "12px"
                                    }}
                                    fluid={images.beforeAfterSkinPen2.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px",
                                        marginTop: "12px"
                                    }}
                                    fluid={images.beforeAfterSkinPen3.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px",
                                        marginTop: "12px"
                                    }}
                                    fluid={images.beforeAfterSkinPen4.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px",
                                        marginTop: "12px"
                                    }}
                                    fluid={images.beforeAfterSkinPen5.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                            </div>
                        </div>
                    </ServiceWrapper>
                    <ServiceWrapper 
                        fluid={images.prfmPic.childImageSharp.fluid}
                        cardText=""
                        id="prfm"
                        title="PRFM"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                        descriptions={[
                            'Platelet-rich fibrin matrix (PRFM) can be utilized for facial injections and in conjunction with microneedling to help rejuvenate the skin, decrease the appearance of wrinkles and promote collagen production. PRFM is produced by utilizing a small sample of your own blood to extract platelet rich plasma (PRP). This plasma is then converted into PRFM and injected back into your skin. This should result in overall skin improvements by supporting collagen production and increased vascularization.',
                        ]}
                    />
                    <ServiceWrapper 
                        fluid={images.laserPic.childImageSharp.fluid}
                        cardText=""
                        id="laser"
                        title="Laser"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                    >
                        <div className={clsx(styles.description, styles.marginTop)}>
                            <div><b>Alma Harmony XL Pro</b> offers a variety of treatment choices utilizing its proprietary Advanced
                                Florescence Technology which can treat skin discolorations, vascular and pigmented lesions as
                                well as rejuvenating the skin and improving fine lines and wrinkles.
                                We offer the following treatments: 
                            </div>
                            <div className={styles.laserSubDescription}>
                                <div className={styles.laserSubSection}>
                                    <b>Clear Lift Plus</b> - Restore skin’s youthful appearance by improving wrinkles and sun-damaged
                                    skin with the latest alternative to skin resurfacing.<br/>
                                    <div className={clsx(styles.clearLiftTaglines, styles.marginTop)}>
                                        VIRTUALLY PAIN FREE ⟡
                                        NO DOWNTIME ⟡
                                        SHORT TREATMENT<span> <Hidden mdUp> ⟡ </Hidden></span>
                                        PROVEN SAFETY ⟡
                                        FOR ALL SKIN COLORS
                                    </div>
                                    <div className={styles.marginTop}>
                                        Clear Lift’s Laser Technology stimulates collagen and treats unwanted pigmentation in the skin
                                        by emitting powerful bursts of light under the surface of the skin. This treatment can achieve
                                        quicker results with virtually no downtime since it avoids any damage to the outer layer of the
                                        skin.
                                    </div>
                                    <Img
                                        style={{
                                            overflow: "hidden",
                                            display: "block",
                                            width: "100%",
                                            height: "auto",
                                            borderRadius: "20px",
                                            marginTop: "12px"
                                        }}
                                        fluid={images.beforeAfterLaserClearLiftPlus.childImageSharp.fluid}
                                        imgStyle={{ objectFit: "fill" }}
                                    />
                                </div>
                                <div className={styles.laserSubSection}>
                                    <div>
                                        <b>DYE-VL</b> - The Harmony Dye VL is great for treating skin conditions such as vascular lesions and
                                        various skin pigmentations. The Dye VL maximizes patients’ comfort and minimizes unwanted
                                        side effects by using its proprietary IN-Motion TM protocol. It combines simultaneous cooling
                                        and gradual heating to deliver great results with little discomfort.
                                    </div>
                                    <Img
                                        style={{
                                            overflow: "hidden",
                                            display: "block",
                                            width: "100%",
                                            height: "auto",
                                            borderRadius: "20px",
                                            marginTop: "12px"
                                        }}
                                        fluid={images.beforeAfterLaserDye.childImageSharp.fluid}
                                        imgStyle={{ objectFit: "fill" }}
                                    />
                                </div>
                                <div className={styles.laserSubSection}>
                                    <div>
                                        <b>SHR / Hair Removal</b> – SHR’s advanced contact cooling and sweeping IN-Motion TM technology
                                        allows for highly effective hair removal treatments with little discomfort and shorter treatment
                                        times. Make Harmony SHR your choice for hair removal.
                                    </div>
                                    <Img
                                        style={{
                                            overflow: "hidden",
                                            display: "block",
                                            width: "100%",
                                            height: "auto",
                                            borderRadius: "20px",
                                            marginTop: "12px"
                                        }}
                                        fluid={images.beforeAfterLaserHairRemoval.childImageSharp.fluid}
                                        imgStyle={{ objectFit: "fill" }}
                                    />
                                </div>
                                <div className={styles.laserSubSection}>
                                    <div>
                                        <b>Harmony Erbium Laser Skin Resurfacing</b> – Get glowing, vibrant skin with the Harmony
                                        Erbium Laser Skin Resurfacing. The Erbium laser ablates the outer layer of the skin which then
                                        sloughs off and is replaced with fresh new skin. This treatment improves wrinkles and skin
                                        texture while promoting skin that appears more supple and rejuvenated.
                                    </div>
                                    <Img
                                        style={{
                                            overflow: "hidden",
                                            display: "block",
                                            width: "100%",
                                            height: "auto",
                                            borderRadius: "20px",
                                            marginTop: "12px"
                                        }}
                                        fluid={images.beforeAfterLaserSkinResurfacing.childImageSharp.fluid}
                                        imgStyle={{ objectFit: "fill" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </ServiceWrapper>
                    {/* Removed for now...add back in if the service is added
                        <ServiceWrapper 
                        fluid={images.microchannelingPic.childImageSharp.fluid}
                        cardText=""
                        id="microchanneling"
                        title="Microchanneling"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                        descriptions={[
                            'AQUAGOLD® fine touch is a patented microchannel microinjector that effectively delivers micro-doses of therapeutics into the skin such as Botox®, fillers and PRFM. This device features twenty, 24 karat gold, microchannels that are thinner than the human hair making it a comfortable procedure with minimal downtime. This can be used on the face, neck, chest or hands. Each treatment is customized per patient.',
                            '***This treatment does not replace normal Botox® or filler treatment for deeper lines but instead can help improve fine lines and promotes skin that appears fresh, glowing and rejuvenated.'
                        ]}
                    /> */}
                    <ServiceWrapper 
                        fluid={images.dermaplaningPic.childImageSharp.fluid}
                        cardText=""
                        id="dermaplaning"
                        title="Dermaplaning"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                        descriptions={[
                            'Dermaplaning is a cosmetic procedure which utilizes a sterile blade to help exfoliate dead skin and fine vellus hairs, also known as "peach fuzz". Dermaplaning is painless, has no down-time, and helps skin appear smoother, fresh and more vibrant.',
                        ]}
                    />
                    <ServiceWrapper 
                        fluid={images.chemicalPeelsPic.childImageSharp.fluid}
                        cardText=""
                        id="chemical_peels"
                        title="Chemical Peels"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                    >
                        <div className={clsx(styles.description, styles.marginTop)}>
                            <div className={styles.facialSubsection}>
                                We offer a variety of light to medium depth chemical peels at SKINsolutions Aesthetics. Chemical peels can help to revitalize dull skin and improve some discolorations, including age spots. Call us for a consultation to discuss which peel options are best for you and your skin type. Some of the peels we offer include Salicylic Acid, Glycolic, Modified Jessner’s, Mesopeel Melanostop Tran3x, Derm Renewal, and TCA peels.
                            </div>
                            <div className={styles.facialSubsection}>
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px"
                                    }}
                                    fluid={images.beforeAfterDermrenewalpeel.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                            </div>
                        </div>
                    </ServiceWrapper>
                    <ServiceWrapper 
                        fluid={images.diamondGlowPic.childImageSharp.fluid}
                        cardText=""
                        id="diamond_glow"
                        title="Diamond Glow®"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                    >
                        <div className={clsx(styles.description, styles.marginTop)}>
                            <div className={styles.diamondGlowSubsection}>
                                DiamondGlow® is more than a facial. Unlike traditional wet facial treatments, which rely on chemical exfoliation to treat the skin at surface level, DiamondGlow’s patented recessed diamond tip wand delivers a next-level resurfacing treatment that deeply cleans and rejuvenates the skin. DiamondGlow® uses 3X1 technology in one simple step. It exfoliates to uncover renewed skin, extracts to deeply cleanse skin and remove debris from pores, and infuses skin with SkinMedica serums while pores are still open and most receptive.
                            </div>
                            <div className={styles.diamondGlowSubsection}>
                                DiamondGlow® promotes skin that is more luminous, dewy and brighter with nourishing SkinMedica Pro-Infusion serums. Results can improve with each treatment.
                            </div>
                            <Img
                                style={{
                                    overflow: "hidden",
                                    display: "block",
                                    width: "100%",
                                    height: "auto",
                                    borderRadius: "20px"
                                }}
                                fluid={images.diamondGlowDiagram.childImageSharp.fluid}
                                imgStyle={{ objectFit: "fill" }}
                            />
                        </div>
                    </ServiceWrapper>
                    <ServiceWrapper 
                        fluid={images.facialsPic.childImageSharp.fluid}
                        cardText=""
                        id="facials"
                        title="Facials"
                        activeServices={activeServices}
                        setActiveServices={setActiveServices}
                    >
                        <div className={clsx(styles.description, styles.marginTop)}>
                            <div className={styles.facialSubsection}>
                                We offer a variety of facials at our office which can be customized to your skin’s needs. Our facials include G.M. Collins clinical treatments, DiamondGlow® facials with SkinMedica Pro-infusions as well as customized facials utilizing our very own skincare line, SKIN prodigy skincare. See the before and after pictures of our popular facials:
                            </div>
                            <div className={styles.facialSubsection}>
                                <div className={styles.facialTitle}>Collagen 90+</div>
                                <div className={styles.facialParagraph}>
                                    This anti-aging facial, exclusive to G.M. Collin, utilizes a native collagen pellicle along with an anti-aging energizing serum, intensive complex and a moisturizing activator. This facial combines peptides, Yeast extract, Hyaluronic Acid,  and natural extracts for promoting skin that appears more revitalized and hydrated. Includes a neck and shoulder massage.
                                </div>
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px"
                                    }}
                                    fluid={images.beforeAfterCollagen90.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                            </div>
                            <div className={styles.facialSubsection}>
                                <div className={styles.facialTitle}>Algomask</div>
                                <div className={styles.facialParagraph}>
                                    This soothing and hydrating facial utilizes an enriched seaweed thermo-cooling treatment that infuses the skin with hyaluronic acid and helps to minimize appearance of redness while soothing the skin. Includes face, neck and shoulder massage.
                                </div>
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px"
                                    }}
                                    fluid={images.beforeAfterAlgomask.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                            </div>
                            <div className={styles.facialSubsection}>
                                <div className={styles.facialTitle}>Hydrolifting</div>
                                <div className={styles.facialParagraph}>
                                    This firming facial includes a double exfoliation utilizing both enzymatic and chemical actives (Glycolic Acid, Pomegranate Ferment Extract, Sea Fennel Extract and Niacinamide), followed by G.M. Collins Hydrolifting serum, then mask to tone the skin with neck and shoulder massage.
                                </div>
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px"
                                    }}
                                    fluid={images.beforeAfterHydrolifting.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                            </div>
                            <div className={styles.facialSubsection}>
                                <div className={styles.facialTitle}>Oxygenating</div>
                                <div className={styles.facialParagraph}>
                                    This revitalizing and purifying facial clears and revitalizes fatigued, stressed and dull skin utilizing a unique Oxygen Complex containing the peptide Matrixyl™ 3000. This is a great treatment for oily or acne-prone skin as it includes a 5-step treatment formulated with BHA (Salicylic Acid), AHAs (Glycolic and Lactic Acids).
                                </div>
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px"
                                    }}
                                    fluid={images.beforeAfterOxygenating.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                            </div>
                            <div className={styles.facialSubsection}>
                                <div className={styles.facialTitle}>Sea C Spa</div>
                                <div className={styles.facialParagraph}>
                                    This brightening facial contains powerful antioxidants with marine and vegetal active ingredients (Vitamin C Concentrate, Algae Biomatrix Patches and Thermal Organic Mud).
                                </div>
                                <Img
                                    style={{
                                        overflow: "hidden",
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "20px"
                                    }}
                                    fluid={images.beforeAfterSeacspa.childImageSharp.fluid}
                                    imgStyle={{ objectFit: "fill" }}
                                />
                            </div>
                        </div>
                    </ServiceWrapper>
                    <div className={styles.disclaimerText}>
                        Disclaimer: Individual results may vary and are not guaranteed either expressed or implied. 
                    </div>
                </Grid>
            </Grid>
            
        </GlobalLayout>
    );
}

function ServiceWrapper({
    fluid,
    cardText,
    id,
    setActiveServices,
    activeServices,
    descriptions,
    title,
    children
}) {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(activeServices.indexOf(id) === -1);
    }, [activeServices, id])

    const setActiveServicesWithThisItem = currentlyActive => {
        if (currentlyActive.indexOf(id) !== -1) {
            return currentlyActive.filter(item => item !== id);
        }
        currentlyActive.push(id);
        return currentlyActive.slice();
    }

    return (
        <div className={styles.headerContainer} id={id}>
            <SectionHeader title={title} />
            <div className={styles.imageContainer}>
                <PictureCard 
                    fluid={fluid} 
                    cardStyles={{ minWidth: '300px'}}
                    textStyles={{ height: 'auto', bottom: '27px' }}
                    text={cardText}
                    onClick={(event) => {
                        event.stopPropagation();
                        setActiveServices(setActiveServicesWithThisItem)
                    }}
                />
            </div>
            <div className={styles.expandContainer}>
                <IconButton
                    className={clsx(styles.expand, {
                        [styles.expandOpen]: expanded,
                    })}
                    onClick={(event) => {
                        event.stopPropagation();
                        setActiveServices(setActiveServicesWithThisItem);
                    }}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                        <FcExpand />
                </IconButton>
            </div>
            <div className={clsx(styles.descriptionContainer, { [styles.displayNone]: expanded })}>
                {
                    descriptions ? descriptions.map(desc => {
                        return (
                            <p key={desc.slice(0, 24)} className={styles.description}>
                                {desc}
                            </p>
                        )
                    }) : children
                }
            </div>
        </div>
    )
}


function useTopMostDiv() {
    const [topmostIndex, setTopmostIndex] = useState(0);

    useEffect(() => {
      let listener = () => {
        const getTopOfElement = (id) => {
            const elem = document.getElementById(id);
            if (elem) {
                return elem.getBoundingClientRect().top;
            }
            return -1;
        }

        
        setTopmostIndex(current => {
            let minTopValue = 999;
            return TAB_IDS.reduce((accum, id, index) => {
                const topOfElem = getTopOfElement(id);
                if (topOfElem < minTopValue && topOfElem > 0) {
                    minTopValue = topOfElem;
                    return index;
                }

                return accum;
            }, current); 
        });
      }

      window.addEventListener("scroll", listener);
  
      return () => {
        if (listener) {
          window.removeEventListener("scroll", listener);
        }
      }
    }, []);

    return topmostIndex;
  }